import React from "react";
import fetch from "isomorphic-fetch"
import { ApolloClient, InMemoryCache, HttpLink } from "apollo-boost";
import { ApolloProvider } from "@apollo/client";

const client = new ApolloClient({
	uri: "https://webmaster.stevestreza.com/graphql",
	cache: new InMemoryCache(),
	link: new HttpLink({
		uri: "https://webmaster.stevestreza.com/graphql",
		fetch,
	}),
	fetch,
});

const wrapRootElement = ({ element }) => {
	return <ApolloProvider client={client}>{element}</ApolloProvider>;
};

export { wrapRootElement }